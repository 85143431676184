import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormInputField, {
  FormInputFieldType,
} from '../../common/components/formFields/FormInputField';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import {
  getDraft,
  getVehicleMakeList,
  getVehicleModelList,
  saveBasicCarDataDraft,
} from '../AddVehicleActions';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { dataToSelect } from '../../common/helpers/dataToSelect';
import calendarIcon from '../../assets/img/icons/calendar.svg';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';

import {
  Doors as VehicleDoors,
  Fuel as VehicleFuels,
  Transmission as VehicleTransmissions,
} from '../../constants/Vehicle';

import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import { AddVehicleStepsMapping } from '../../constants/AddVehicle';
import AddVehicleFooter from '../AddVehicleFooter';
import { ADD_DRAFT_VALIDATION_ERRORS, SET_BASIC_DATA_SUCCESS } from '../AddVehicleReducer';
import { validateVehicleBasicData } from '../helpers/draftValidator';

const mapArrayToSelectOptions = list => {
  return list.map(el => ({
    value: el,
    label: el,
  }));
};

const yearOptions = (() => {
  const options = [];

  const currentYear = moment().year();
  for (let i = 0; i <= 100; i++) {
    options.push({ value: currentYear - i, label: currentYear - i });
  }

  return options;
})();

const doorsOptions = mapArrayToSelectOptions(VehicleDoors);

const fuelOptions = mapArrayToSelectOptions([
  VehicleFuels.PETROL,
  VehicleFuels.DIESEL,
  VehicleFuels.ELECTRIC,
  VehicleFuels.LPG,
  VehicleFuels.HYBRID,
  VehicleFuels.CNG,
  VehicleFuels.HYDROGEN,
]);

const transmissionOptions = mapArrayToSelectOptions([
  VehicleTransmissions.MANUAL,
  VehicleTransmissions.AUTOMATIC,
  VehicleTransmissions.SEMI_AUTOMATIC,
  VehicleTransmissions.CVT,
  VehicleTransmissions.DUAL_CLUTCH,
]);

const AddBasicCarDataForm = ({ values, nextForm, form }) => {
  const dispatch = useDispatch();
  const valuesRef = useRef();
  const [makeOptions, setMakeOptions] = useState();
  const [modelOptions, setModelOptions] = useState();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const basicData = useSelector(state => state.addVehicle.get('basicData'));
  const id = useSelector(state => state.addVehicle.get('id'));

  const isElectricFuel = values?.basicData?.fuel?.value === VehicleFuels.ELECTRIC;

  const [errors] = useState({
    registrationPlateError: '',
    millageError: '',
    makeIdError: '',
    modelIdError: '',
    yearError: '',
    derivativeError: '',
    carTypeError: '',
    transmissionError: '',
    engineSizeError: '',
    fuelError: '',
    colourError: '',
    doorsError: '',
    dateOfFirstRegistrationError: '',
    previousKeepersInTotalError: '',
    vinError: '',
  });

  // const moveToNextStep = () => {
  //   const {
  //     registrationPlate,
  //     mileage,
  //     year,
  //     mot,
  //     derivative,
  //     carType,
  //     transmission,
  //     engineSize,
  //     fuel,
  //     colour,
  //     doors,
  //     dateOfFirstRegistration,
  //     previousKeepersInTotal,
  //     vehicleCapCapId,
  //     vin,
  //   } = values?.basicData;
  //
  //   const data = {
  //     ...values,
  //     basicData: {
  //       registrationPlate,
  //       mileage: parseStringToInt(mileage),
  //       year: year?.value ? parseStringToInt(year.value) : '',
  //       mot: mot || null,
  //       derivative,
  //       carType,
  //       transmission: transmission?.value || '',
  //       engineSize: engineSize !== '' ? parseStringToInt(engineSize) : '',
  //       fuel: fuel?.value || '',
  //       colour,
  //       doors: doors?.value ? parseStringToInt(doors.value) : '',
  //       dateOfFirstRegistration: reverseDateToString(dateOfFirstRegistration),
  //       previousKeepersInTotal: parseStringToInt(previousKeepersInTotal),
  //       vehicleCapCapId,
  //       vin: vin || null,
  //     },
  //   };
  //
  //   validateForm()
  //     .then(() =>
  //       dispatch(
  //         validateBasicCarData(
  //           {
  //             values: data.basicData,
  //             modelId: values?.basicData?.modelSelect?.value,
  //             makeId: values?.basicData?.makeSelect?.value,
  //           },
  //           () => nextForm(),
  //         ),
  //       ),
  //     )
  //     .catch(() => {
  //       nextForm();
  //     });
  // };

  const {
    registrationPlateError,
    millageError,
    makeIdError,
    modelIdError,
    yearError,
    derivativeError,
    carTypeError,
    transmissionError,
    engineSizeError,
    fuelError,
    colourError,
    doorsError,
    dateOfFirstRegistrationError,
    previousKeepersInTotalError,
    vinError,
  } = errors;

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makeList => {
        setMakeOptions(dataToSelect(makeList?.data, ['id', 'name']));
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (basicData?.makeId) {
      dispatch(
        getVehicleModelList(basicData?.makeId, modelList => {
          setModelOptions(dataToSelect(modelList?.data, ['id', 'name']));
        }),
      );
    }
  }, [dispatch, basicData]);

  useEffect(
    () => () => {
      dispatch({
        type: SET_BASIC_DATA_SUCCESS,
        payload: {
          id,
          basicData: {
            ...valuesRef.current?.basicData,
            mileage: valuesRef.current?.basicData?.mileage,
            makeId: valuesRef.current?.basicData?.makeSelect?.value,
            makeName: valuesRef.current?.basicData?.makeSelect?.label,
            modelId: valuesRef.current?.basicData?.modelSelect?.value,
            modelName: valuesRef.current?.basicData?.modelSelect?.label,
            year: valuesRef.current?.basicData?.year?.value,
            transmission: valuesRef.current?.basicData?.transmission?.value,
            fuel: valuesRef.current?.basicData?.fuel?.value,
            doors: valuesRef.current?.basicData?.doors?.value,
          },
        },
      });
      handleSaveDraft();
    },
    [],
  );

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const handleMakeSelectChange = e => {
    form.setFieldValue('basicData.modelSelect', { value: '', label: '' });
    dispatch(
      getVehicleModelList(e.value, modelList => {
        setModelOptions(dataToSelect(modelList?.data, ['id', 'name']));
      }),
    );
  };

  const handleSaveDraft = () => {
    dispatch({
      type: ADD_DRAFT_VALIDATION_ERRORS,
      payload: {
        formStep: 3,
        errors: validateVehicleBasicData(valuesRef.current),
      },
    });
    dispatch(
      saveBasicCarDataDraft(
        id,
        {
          ...valuesRef.current.basicData,
          year: valuesRef.current.basicData?.year?.value,
          transmission: valuesRef.current.basicData?.transmission?.value,
          fuel: valuesRef.current.basicData?.fuel?.value,
          doors: valuesRef.current.basicData?.doors?.value,
          modelId: valuesRef.current?.basicData?.modelSelect?.value,
          makeId: valuesRef.current?.basicData?.makeSelect?.value,
        },
        id => dispatch(getDraft(id)),
      ),
    );
  };

  return (
    <>
      <AddVehicleHeader saveDraftButtonHandler={handleSaveDraft} />
      <AddVehicleContentWrapper>
        <Form className="text-left">
          <Row className="mb-4">
            <Col>
              <h5 className="font-weight-bold m-0">Vehicle Details</h5>
              <p className="text-sm mt-2">Add detailed information about the vehicle.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="mb-4">
                <Col md="4" sm="12">
                  <FormInputField
                    name="basicData.registrationPlate"
                    className="basic-data__input"
                    label="Vehicle registration plate"
                    invalid={!!registrationPlateError}
                    maxLength={10}
                    disabled
                    upperCase
                    required
                  />
                  <CommonErrorLabel value={registrationPlateError} />
                </Col>
                <Col md="4" sm="12">
                  <FormSelectField
                    name="basicData.makeSelect"
                    className="basic-data__input"
                    label="Make"
                    options={makeOptions}
                    onChangeHandler={handleMakeSelectChange}
                    invalid={!!makeIdError}
                    required
                  />
                  <CommonErrorLabel value={makeIdError} />
                </Col>
                <Col md="4" sm="12">
                  <FormSelectField
                    name="basicData.modelSelect"
                    className="basic-data__input"
                    label="Model"
                    options={modelOptions}
                    invalid={!!modelIdError}
                    required
                  />
                  <CommonErrorLabel value={modelIdError} />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="12" sm="12">
                  <FormInputField
                    name="basicData.derivative"
                    className="basic-data__input"
                    label="Derivative"
                    invalid={!!derivativeError}
                    maxLength={150}
                    lengthCounter={true}
                    upperCase
                    required
                  />
                  <CommonErrorLabel value={derivativeError} />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="3" sm="12">
                  <FormInputField
                    type={FormInputFieldType.NUMBER}
                    name="basicData.mileage"
                    className="basic-data__input"
                    label="Mileage"
                    invalid={!!millageError}
                    maxLength={9}
                    required
                  />
                  <CommonErrorLabel value={millageError} />
                </Col>
                <Col md="3" sm="12">
                  <FormSelectField
                    name="basicData.year"
                    className="basic-data__input"
                    label="Year"
                    options={yearOptions}
                    invalid={!!yearError}
                    required
                  />
                  <CommonErrorLabel value={yearError} />
                </Col>
                <Col md="3" sm="12">
                  <FormDatePickerField
                    name="basicData.dateOfFirstRegistration"
                    label="Date of registration"
                    icon={calendarIcon}
                    iconClassName="calendar-icon"
                    placeholder="DD/MM/YYYY"
                    invalid={!!dateOfFirstRegistrationError}
                    readOnly
                    required
                  />
                  <CommonErrorLabel value={dateOfFirstRegistrationError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.colour"
                    label="Colour"
                    invalid={!!colourError}
                    maxLength={40}
                    required
                    capitalize
                  />
                  <CommonErrorLabel value={colourError} />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="3" sm="12">
                  <FormSelectField
                    name="basicData.doors"
                    className="basic-data__input"
                    label="Doors"
                    options={doorsOptions}
                    invalid={!!doorsError}
                    selectProps={{
                      menuPlacement: 'top',
                    }}
                    required
                  />
                  <CommonErrorLabel value={doorsError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.carType"
                    label="Body type"
                    invalid={!!carTypeError}
                    maxLength={20}
                    required
                    capitalize
                  />
                  <CommonErrorLabel value={carTypeError} />
                </Col>
                <Col md="3" sm="12">
                  <FormSelectField
                    name="basicData.fuel"
                    className="basic-data__input"
                    label="Fuel"
                    options={fuelOptions}
                    invalid={!!fuelError}
                    selectProps={{
                      menuPlacement: 'top',
                    }}
                    onChangeHandler={value => {
                      if (value?.value === VehicleFuels.ELECTRIC) {
                        form.setFieldValue('basicData.engineSize', '');
                      }
                    }}
                    required
                  />
                  <CommonErrorLabel value={fuelError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    type={FormInputFieldType.NUMBER}
                    name="basicData.engineSize"
                    className="basic-data__input"
                    label="Engine size"
                    invalid={!!engineSizeError}
                    maxLength={9}
                    disabled={isElectricFuel}
                    required={!isElectricFuel}
                  />
                  <CommonErrorLabel value={engineSizeError} />
                </Col>
              </Row>
              <Row>
                <Col md="3" sm="12">
                  <FormSelectField
                    name="basicData.transmission"
                    className="basic-data__input"
                    label="Transmission"
                    options={transmissionOptions}
                    invalid={!!transmissionError}
                    selectProps={{
                      menuPlacement: 'top',
                    }}
                    required
                  />
                  <CommonErrorLabel value={transmissionError} />
                </Col>{' '}
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.previousKeepersInTotal"
                    className="basic-data__input"
                    label="Number of prev. owners"
                    type={FormInputFieldType.NUMBER}
                    invalid={!!previousKeepersInTotalError}
                    maxLength={2}
                    required
                  />
                  <CommonErrorLabel value={previousKeepersInTotalError} />
                </Col>
                <Col md="3" sm="12">
                  <FormDatePickerField
                    name="basicData.mot"
                    label="MOT"
                    icon={calendarIcon}
                    placeholder="DD/MM/YYYY"
                    iconClassName="calendar-icon"
                    readOnly
                    position="top"
                  />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.vin"
                    className="basic-data__input"
                    label="VIN/Chassis No."
                    invalid={!!vinError}
                    maxLength={17}
                    upperCase
                    required
                  />
                  <CommonErrorLabel value={vinError} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          nextForm();
        }}
        backButtonHandler={() => {
          nextForm(AddVehicleStepsMapping.VEHICLE_REGISTRATION);
        }}
        nextButtonDisabled={!!isLoading}
      />
    </>
  );
};

AddBasicCarDataForm.propTypes = {
  values: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default AddBasicCarDataForm;
